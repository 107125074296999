import React, { Component } from 'react'
import AdminForm from './AdminForm'
import AddSection from './AddSection'

//Firebase
import * as firebase from 'firebase'

class Admin extends Component {
    state = {
        uid : null,
        owner : null
    }

    componentDidMount () {
        const user = firebase.auth().currentUser
        console.log(user)
        if (user) {
            this.authHandler(user)
        }
    }

    authenticate = event => {
        event.preventDefault()
        firebase.auth().signInWithEmailAndPassword(this.mail.value, this.password.value)
        .then(user => this.authHandler(user))
        console.log(this.mail.value, this.password.value)
    }

    authHandler = user => {
        const ref = firebase.database().ref('poitier/owner')
        ref.on('value', snapshot => {
            this.setState({
                uid: user.uid,
                owner: snapshot.val()
            })
        })
    }

    logout = () => {
        firebase.auth().signOut()
        .then (this.setState({ uid: null }))
    }

    renderLogin = () => (
        <div className='app'>
            <header>
                <h1>Administration</h1>
            </header>
            <section>
                <form onSubmit={this.authenticate}>
                    <input placeholder='email' type='email' ref={input => this.mail = input}/>
                    <input placeholder='password' type='password' ref={input => this.password = input}/>
                    <button type='submit'>Se connecter</button>
                </form>
            </section>
        </div>
    )

    render() {
        //Si personne connectée
        if (!this.state.uid) {
            return this.renderLogin()
        }

        if (this.state.uid !== this.state.owner) {
            return this.renderLogin()
        }

        const form= Object.keys(this.props.data.sections).map(key => (
            <AdminForm key={key} id={key} data={this.props.data.sections[key]} /> //retour de tous ce qui a dans section par rapport a sa key
        ))
        
        return (
            <div className='app'>
                <header>
                    <h1> Admin </h1>
                </header>
            {form}
            <AddSection/>
            <footer>
                <button onClick={this.logout}>Se Déconnecter</button>
            </footer>
            </div>
        )
    }
}

export default Admin