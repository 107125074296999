import React from 'react'
import ReactLoading from 'react-loading'

import '../css/loading.css'

const Loading = () => (
    <div className='loading'>
        <ReactLoading
            type='cylon'
        />
    </div>
)

export default Loading
