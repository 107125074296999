import React from 'react'

import Header from './Header'
import Section from './Section'
import Footer from './Footer'

const App = props => {
            //On crée une constante avec un objet avec une propriété keys pour creer un tableau Boucle dans section
            const sections= Object.keys(props.data.sections).map(key => (
                <Section key={key} details={props.data.sections[key]} /> //retour de tous ce qui a dans section par rapport a sa key
            ))

        return(
            <div className='app'>
                <Header info={props.data.info}/>
                {sections}
                <h2>Carte</h2>
                <Footer info={props.data.info}/>
            </div>
        )
}

export default App
