import React, { Component } from 'react'

//Firebase
import * as firebase from 'firebase'

class AddSection extends Component {
state = { //autre façon d"ecrire un state
    data: {
        color: '#fff',
        content: 'Entrez vôtre texte...'
        }
    }


    handleChange = event => {
        const data = {...this.state.data}//copie des data
        const name = event.target.name //pour differencier name color ou content
        data[name] = event.target.value //modif du data
        this.setState ({ data })//remplace du state par data
    }

    handleClick = event => {
        firebase.database().ref('poitier/sections').push({
        ...this.state.data
        })
        this.setState({
            data: {
                color: '#fff',
                content : 'Entrez cotre texte...'
            }
        })
    }
    render() {
        return (
            <section>
                <input
                    name='color'
                    type='color'
                    value={this.state.data.color}
                    onChange={this.handleChange}
                />
                <textarea
                    rows='10'
                    value={this.state.data.content}
                    onChange ={this.handleChange} //Pour pouvoir changer le texte
                />
                <button onClick={this.handleClick}>Ajoutez une section</button>
            </section>
        )
    }
}

export default AddSection