import React from 'react'


//Création d'un component () pour retourner juste 
const Header = props => (
    <header>
        <h1>{props.info.titre}</h1>
    </header>
)

export default Header