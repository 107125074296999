import React from 'react'

//Création d'un component () pour retourner juste 
const Footer = props => (
    <footer>
        <h1>{props.info.nom}</h1>
        <h2>Tel:<a href={`tel:${props.info.tel}`}>{props.info.tel}</a> tete</h2>
    </footer>
)

export default Footer
