import React from 'react'

import marked from 'marked'

//Création d'un component
const Section = props => {
    const renderText = text => {
        const renderText = marked(text)
        return {__html: renderText}
}
    return(
        <section style={{backgroundColor: props.details.color}}>
            <div dangerouslySetInnerHTML={renderText(props.details.content)}/>
        </section>
    )
}

export default Section
