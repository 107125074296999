import React, { Component } from 'react'

//Firebase
import * as firebase from 'firebase'

class AdminForm extends Component {
    constructor(props) {
        super(props)
        const data= this.props.data
        this.state = { data }
    }

componentDidUpdate () {
    this.updateContent()
    }

//Mettre à jour le contenu
updateContent = () => {
    firebase.database().ref(`poitier/sections/${this.props.id}`).set({
        ...this.state.data
    })
}

    handleChange = event => {
        const data = {...this.state.data} //copie des data
        const name = event.target.name //pour differencier name color ou content
        data[name] = event.target.value //modif du data
        this.setState ({ data }) //remplace du state par data
    }

    render() {
        return (
            <section>
                <input
                    name='color'
                    type='color'
                    value={this.state.data.color}
                    onChange={this.handleChange}
                />
                <textarea
                    name='content'
                    rows='10'
                    value={this.state.data.content}
                    onChange ={this.handleChange} //Pour pouvoir changer le texte
                />
            </section>
        )
    }
}

export default AdminForm