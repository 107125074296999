import React, { Component } from 'react'
import ReactDOM from 'react-dom' //Pour Afficher le rendu
//import Connexion from './Components/Connexion'

//React router
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'

//Component
import App from './Components/App'
import Admin from './Components/Admin'
import Loading from './Components/Loading'

//CSS
import 'sanitize.css'
import './css/style.css'

//PWA
import * as serviceWorker from './serviceWorker'

//Firebase
import * as firebase from 'firebase'
import config from './firebase'

//Router les différentes pages
class Root extends Component {

     //Initialisation du component
     constructor () {
        super()
        firebase.initializeApp(config)
        this.state = {
            loading: true
        }
    }
    //Lorsque le component se monte connexion a la base de donnée
    componentWillMount () {
        const ref = firebase.database().ref('poitier')
    
        //Récuperer valeurs de la base
        ref.on('value', snapshot => {
                this.setState({
                    data: snapshot.val(),
                    loading: false
                }) //Mise à jour de la valeur
        })
    }   

    render () {
        if (this.state.loading) {
            return <Loading />
        }
        return (
            <Router>
                <Switch>
                    <Route exact path='/' render={props => (
                        <App data={this.state.data} />
                    )} />
                    <Route exact path='/admin' render={props => (
                        <Admin data={this.state.data} />
                    )} />
                </Switch>
            </Router>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root')) //rends le component APP vers le document Id Root (vers fichier index)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
